
// Vue reactivity
import { computed, watch } from 'vue';

// icons
import { arrowBack, } from 'ionicons/icons';

// components
import { IonPage, IonGrid, IonHeader, IonFooter, IonToolbar, IonTitle, IonContent, IonSpinner,
        IonButtons, IonButton, IonIcon, IonLabel, IonBackButton,
        onIonViewDidEnter, onIonViewWillLeave, } from '@ionic/vue';

// API services
import { useStore } from '@/store';
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useRoute } from 'vue-router';

// types
import { Session } from '@/types';
import ABSService from '@/services/ABSService';
import AuthService from '@/services/AuthService';

// Firebase
import { customTokenSignin } from '@/auth';

export default {
  name: 'WorkEventPage',
  components: { IonPage, IonGrid, IonHeader, IonFooter, IonToolbar, IonTitle, IonContent, IonSpinner,
                IonButtons, IonButton, IonIcon, IonLabel, IonBackButton, },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const route = useRoute();
    const { id: eventId, userId } = route.params;
    //const { uid: userId } = route.query;
    let { showActions } = route.query;
    let showUnsubscribePrompt = false, showApplyPrompt = false;
    let showConfirmPrompt = false, showWithdrawPrompt = false;
    if (route.path.startsWith("/confirm") || route.path.startsWith("/withdraw")) {
      showActions = '1'; // show confirm / withdraw buttons
      if (route.path.startsWith("/confirm")) {
        showConfirmPrompt = true;
      } else {
        showWithdrawPrompt = true;
      }
    }
    if (route.path.startsWith("/unsubscribe")) {
      showUnsubscribePrompt = true; // show unsubscribe prompt
    }
    else if (route.path.startsWith("/apply")) {
      showApplyPrompt = true; // show Apply prompt
    }

    // 1. declare state variables (ref to make them reactive)
    const loading = computed(() => store.state.loadingData || store.state.loadingUser);
    const user = computed(() => store.state.user);
    const event = computed<Session>(() => store.getters.getSessionById(eventId));
    const hideTeacherResponse = computed(() => ["Teacher Event"].includes(event.value.group))

    let accessTime, duration = 0, counterInterval;
    onIonViewDidEnter(() => {
      // Initial Access
      ABSService.insertPageAccessRecord(`WorkEventPage | ${eventId}`, new Date(), null, userId);

      // Record Access & Leave Time
      accessTime = new Date();
      counterInterval = setInterval(() => (duration++), 1000);
    });
    onIonViewWillLeave(() => {
      if (accessTime && duration >= 3) {
        const { id, name } = event.value || {};
        ABSService.insertPageAccessRecord(`WorkEventPage | ${name} | ${id}`, accessTime, new Date(accessTime.getTime() + duration*1000), userId);
        accessTime = undefined;
        duration = 0; // reset;
        clearInterval(counterInterval);
      }
    });

    watch(user, async (currUser) => {
      if (currUser.id == null || (currUser.phone != userId && currUser.waPhoneNumber != userId)) { // userId = phone
        store.commit('setLoadingUser', true);
        const existingFirebaseUser = await AuthService.checkPhoneExists(userId, null, true); // userId is the phone
        if (existingFirebaseUser && existingFirebaseUser.s == 200 && existingFirebaseUser.t) {
          await customTokenSignin(existingFirebaseUser.t);
        } else {
          store.commit('setLoadingUser', false);
        }
      } else if (currUser.phone != userId && currUser.waPhoneNumber != userId) {
        store.dispatch('checkOnetimeGetUserInfo', userId);
      }
    })
    
    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      arrowBack,

      // variables
      loading,
      event, userId, showActions,
      showUnsubscribePrompt, showApplyPrompt,
      showConfirmPrompt, showWithdrawPrompt, hideTeacherResponse, 
      user,

      // methods
      t,
    }
  },
}
